import React from "react";

import { SocialIcon, PageBand } from "msappfwk";

import "./Footer.css";

export const Footer = (props) => {
    return (
        <PageBand bgColour="#222222">
            <footer id="footer">
                <div>
                    <div className="logo">
                        <img
                            src="/images/kashm-logo.svg"
                            alt="KASHM Logo"
                            style={{ width: "200px" }}
                        />
                    </div>
                    <div className="sections">
                        <div>
                            <h2>Company</h2>
                            <ul>
                                <li>
                                    <a href="/About">About</a>
                                </li>
                                <li>
                                    <a href="/Contact">Contact</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Policies</h2>
                            <ul>
                                <li>
                                    <a href="/PrivacyPolicy">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/Statements">Statements</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Follow Us</h2>
                            <ul>
                                <li>
                                    <SocialIcon
                                        network="instagram"
                                        url="https://www.instagram.com/Prestigecar.rentals/"
                                        width="20px"
                                    />
                                    <a href="https://www.instagram.com/Prestigecar.rentals/">
                                        Instagram
                                    </a>
                                </li>
                                <li>
                                    <SocialIcon
                                        network="facebook"
                                        url="https://www.facebook.com/prestige.car.rentals.berkshire"
                                        width="20px"
                                    />
                                    <a href="https://www.facebook.com/prestige.car.rentals.berkshire">
                                        Facebook
                                    </a>
                                </li>
                                <li>
                                    <SocialIcon
                                        network="linkedin"
                                        url="https://linkedin.com"
                                        width="20px"
                                    />
                                    <a href="https://linkedin.com">LinkedIn</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <p>
                        &copy; 2022-2023 KASHM Technologies LLC | Cluster R,
                        Jumeriah Lake Towers, Dubai, UAE. All Rights Reserved.
                    </p>
                </div>
            </footer>
        </PageBand>
    );
};
