import React from "react";

import { PageBand } from "msappfwk";

import "./Consultancy.css";

import { Hero } from "../../Components/Hero/Hero.jsx";
import Offices from "../../Components/Offices/Offices.jsx";
import { Footer } from "../../Components/Footer/Footer.jsx";

/***************************************************************************************
 *
 * Home
 *
 ***************************************************************************************/

const Comp = () => {
    return (
        <div id="home">
            <Hero img="/images/Hero_Desktop_03.jpeg">
                <div id="herotext">Welcome to Mainstream Computer Systems</div>
            </Hero>
            <PageBand id="band1">
                <div id="consultancy" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        Business Consultancy
                    </h2>
                    <div class="fsize-s light">
                        First class people delivering a first class service. A
                        simple philosophy that has enabled Mainstream to become
                        a trusted and proven partner to world leading Media, IT
                        and Communications providers. With an outstanding talent
                        pool, highly adaptable service models and the capability
                        to rapidly mobilise, Mainstream are the partner of
                        choice for your consultancy needs.
                    </div>
                </div>
                <div id="techservices" className="infoBox">
                    <div class="fsize-s light">
                        Great things in business are never done by one person.
                        They're done by a team of people.
                    </div>
                    <figure class="author">
                        <figcaption class="pull-left caption light fsize-xxs padding-top-l padding-xl">
                            <h3 class="boldcond fsize-21">Steve Jobs</h3>
                        </figcaption>
                    </figure>
                </div>
            </PageBand>

            <PageBand id="band1">
                <div id="consultancy" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        The Process
                    </h2>
                    <div class="fsize-s light">
                        We begin the process by understanding the issues and
                        what needs to be undertaken, setting how we plan to
                        assist and the projected benefits, in a clear and
                        concise document. Most of our work is undertaken on a
                        fixed price basis. Once this has been agreed together
                        then we roll up our sleeves and assist you and your team
                        to achieve lasting change, meeting or exceeding the
                        strategic aims and objectives set out, and leaving you
                        and your team skilled to continue the good improvements.
                        <br />
                        <br />
                        Each assignment is tailored individually to the client,
                        their culture and needs and what exactly will work for
                        them. We normally work in small teams, selecting the
                        particular technical knowledge that each consultant has
                        to build the optimum team.
                    </div>
                </div>
                <div id="techservices" className="infoBox"></div>
            </PageBand>

            <PageBand id="band1">
                <div id="consultancy" className="infoBox"></div>
                <div id="techservices" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        Honesty and Integrity
                    </h2>
                    <div class="fsize-s light">
                        Today, 94% of our work is with repeat clients. Our
                        client relationships are lasting because our solutions
                        not only maximize results and ensure enduring growth,
                        but because they are developed with an uncommon level of
                        honesty and integrity. Through the thoughtful
                        integration of insights, technology and design we
                        generate innovative solutions and big ideas. And then we
                        bring them to life with a fierce accountability to the
                        people we work for and with.
                    </div>
                </div>
            </PageBand>

            <Footer />
        </div>
    );
};

export default Comp;
