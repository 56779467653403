import React from "react";

import { PageBand } from "msappfwk";

import "./Design.css";

import { Hero } from "../../Components/Hero/Hero.jsx";
import Offices from "../../Components/Offices/Offices.jsx";
import { Footer } from "../../Components/Footer/Footer.jsx";

/***************************************************************************************
 *
 * Design
 *
 ***************************************************************************************/

const Comp = () => {
    return (
        <div id="home">
            <Hero img="/images/Hero_Desktop_03.jpeg">
                <div id="herotext">Welcome to KASHM Technologies</div>
            </Hero>
            <PageBand id="band1">
                <div id="consultancy" className="infoBox"></div>
                <div id="techservices" className="infoBox">
                    <h2>Our Story</h2>
                    This year will see the 20th year in delivering quality
                    services to our customers. KASHM Technologies has
                    focused on helping our large FTSE 100 companies with every
                    aspect of their technology needs. From product conception,
                    solution design, build/buy analysis, vendor selection right
                    through to delivery and launch. Mainstream has provided the
                    skills and expertise to deliver major technology solutions
                    to the telecommunications, water, gas and electricity
                    markets.
                    <p class="more fsize-s">
                        <a href="/en/About/Story">Read More</a>
                    </p>
                </div>
            </PageBand>
            <PageBand id="band1">
                <div id="consultancy" className="infoBox">
                    <h2>CORE PURPOSE & VALUES</h2> We exist to create an
                    environment and a culture that breeds success by caring for
                    our customers as individuals and enabling our people to be
                    the best that they can be. Read More
                </div>
                <div id="techservices" className="infoBox">
                </div>
            </PageBand>
            <Offices />
            <Footer />
        </div>
    );
};

export default Comp;
