import React from "react";

import "./Hero.css";

export const Hero = ({ id, className, img, text, children }) => {
    return (
        <div id={id} className={className} style={{ position: "relative" }}>
            <div className="hero zoom-pan-container">
                <img id="zoom-pan-image" src={img} alt="" />
            </div>
            <div className="text">{text}</div>
            {children}
        </div>
    );
};
