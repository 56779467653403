import React from "react";

import { PageBand } from "msappfwk";

import { Hero } from "../../Components/Hero/Hero.jsx";
import { Footer } from "../../Components/Footer/Footer.jsx";

import "./Statements.css";

/***************************************************************************************
 *
 * Privacy Policy
 *
 ***************************************************************************************/

const Comp = () => {
    return (
        <div id="privacy">
            <Hero>
                <div id="herotext">Privacy Policy</div>
            </Hero>
            <PageBand id="band1">
                <div id="consultancy" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        Modern Slavery Statement
                    </h2>
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 fsize-s light">
                            <strong>
                                This statement has been published in accordance
                                with the Modern Slavery Act 2015. It sets out
                                the Company’s commitment to prevent modern
                                slavery and human trafficking in its business
                                and supply chains.
                            </strong>
                        </div>
                    </div>
                </div>
                <div id="techservices" className="infoBox"></div>
            </PageBand>

            <PageBand id="band1">
                <div id="consultancy" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        Our Business
                    </h2>
                    <div class="fsize-s light">
                        KASHM Technologies is an IT services firm
                        delivering IT strategy and software development,
                        implementation, support and management to some of the
                        world’s leading Finance, Insurance, Telecommunications,
                        Media, Technology, Retail and Travel companies helping
                        them evolve and transform their businesses. The Company
                        has built a foundation of industry expertise,
                        engineering excellence and nearshore delivery.
                        <br />
                        <br />
                        KASHM Technologies has offices based in Western
                        Europe and North America, and delivery centers in
                        Central Europe and Latin America. The Company has
                        developed a set of services that support our customers
                        from initial idea through to production, to enable them
                        to provide products and services to their customers.
                    </div>{" "}
                </div>
                <div id="techservices" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        Our Stance on Modern Slavery
                    </h2>
                    <div class="fsize-s light">
                        As a Company and the way we operate, we are committed to
                        ensuring that there is no human trafficking or modern
                        slavery in any part of our business or in our supply
                        chain. It is important that people working in our
                        business, and for our suppliers, work in safe, fair and
                        legal conditions, and we will make every effort to
                        ensure that we act ethically and with integrity in all
                        of our business relationships. If employees have
                        concerns in how we conduct our business, we have a Speak
                        Up policy where concerns and breaches can be reported.
                    </div>
                </div>
            </PageBand>

            <PageBand id="band1">
                <div id="consultancy" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        Employees
                    </h2>
                    <div class="fsize-s light">
                        We have a long-standing policy that we do not use or
                        accept forced, bonded or involuntary prison labour or
                        child labour; nor demand deposits or hold onto our
                        workers’ identity papers, or work with businesses that
                        do. We only work with people who choose to work freely,
                        and we respect the right to equal opportunity, freedom
                        of association and collective bargaining. Our working
                        practices respect and uphold all human rights, and we
                        develop our employees through providing training and
                        development programmes, as well as providing free
                        English language courses.
                    </div>
                </div>
                <div id="techservices" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        Supply Chains
                    </h2>
                    <div class="fsize-s light">
                        Our supply chains include a network of suppliers across
                        different countries which mainly include goods and
                        services for use in an office environment. We recognise
                        there is complexity of the supply chains that extends
                        beyond our immediate suppliers, meaning that the chain
                        is likely to have a global reach.
                    </div>
                </div>
            </PageBand>

            <PageBand>
                <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                    Further Steps
                </h2>
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 fsize-s light">
                        In light of the Modern Slavery Act, and to ensure there
                        is no slavery or human trafficking in our business or
                        supply chains, we intend to take the following further
                        steps in our next financial year to prevent slavery and
                        human trafficking:
                        <br />
                        <br />
                        ■ Develop a system to track the number of suppliers
                        evaluated at pre-qualification stage for compliance with
                        the <br />
                        Modern Slavery Act 2015;
                        <br />
                        <br />■ Provide training and awareness to relevant
                        stakeholders.
                    </div>
                </div>
            </PageBand>

            <Footer />
        </div>
    );
};

export default Comp;
