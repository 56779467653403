import React from "react";

import { PageBand } from "msappfwk";

import { Hero } from "../../Components/Hero/Hero.jsx";
import { Footer } from "../../Components/Footer/Footer.jsx";

import "./Privacy.css";

/***************************************************************************************
 *
 * Privacy Policy
 *
 ***************************************************************************************/

const Comp = () => {
    return (
        <div id="privacy">
            <Hero>
                <div id="herotext">Privacy Policy</div>
            </Hero>
            <PageBand id="band1">
                <div id="consultancy" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        About Us
                    </h2>
                    <div class="fsize-s light">
                        KASHM Technologies LLC is incorporated in
                        England with registered number 03467013 having its
                        registered office at 527 Moseley Road, Balsall Heath,
                        Birmingham, West Midlands, B12 9BU, United
                        Kingdom.&nbsp;
                        <br />
                        <br />
                        All references to "we", "us" or "our" refer to
                        KASHM Technologies LLC and its affiliates and
                        subsidiaries (&ldquo;Mainstream&rdquo;).&nbsp;
                        <br />
                        <br />
                        Our Privacy Policy explains (i) what information we
                        collect and why we collect such information; (ii) how we
                        use the information we collect; (iii) the use of cookies
                        by Mainstream and a description of Mainstream cookies
                        and other third party cookies; and (iv) what are your
                        choices to control, access and modify the information we
                        collect.
                    </div>
                </div>
                <div id="techservices" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        Information that we may collect from you
                    </h2>
                    <div class="fsize-s light">
                        We may collect and process the following data about you:
                        <br />
                        By using the contact us form, you consent to sending us:
                        <br />
                        <br />
                        ■ The reason for the enquiry; and
                        <br />
                        ■ Your name, phone number, email address and a message.
                        <br />
                        <br />
                        If you apply for a job position through our website, you
                        consent to sending us:
                        <br />
                        <br />
                        ■ Your name, phone number, email address and a message;
                        and
                        <br />
                        ■ Your CV document.
                        <br />
                        <br />
                        The details from the contact us form are sent via email
                        to the relevant department at Mainstream;
                        <br />
                        <br />
                        Any information collected and stored in cookies as
                        listed in the section below.
                    </div>
                </div>
            </PageBand>
            <PageBand id="band1">
                <div id="consultancy" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        How we use your information
                    </h2>
                    <div class="fsize-s light">
                        This privacy policy tells you what to expect when
                        Mainstream collects personal information. It applies to
                        information we collect about:
                        <br />
                        <br />
                        ■ visitors to our websites; and
                        <br />■ job applicants.
                    </div>
                </div>
                <div id="techservices" className="infoBox">
                    <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                        Visitors to our websites and job applicants
                    </h2>
                    <div class="fsize-s light">
                        When someone visits www.mscsl.co.uk&nbsp;we collect
                        standard internet log information and details of visitor
                        behaviour patterns. We do this to find out things such
                        as the number of visitors to the various parts of the
                        site. We collect this information in a way which does
                        not identify anyone. We do not make any attempt to find
                        out the identities of those visiting either of our
                        websites. We will not associate any data gathered from
                        this site with any personally identifying information
                        from any source. If we do want to collect personally
                        identifiable information through our website, we will be
                        up front about this. We will make it clear when we
                        collect personal information and will explain what we
                        intend to do with it.
                    </div>
                </div>
            </PageBand>

            <PageBand>
                <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                    Use of cookies by Mainstream
                </h2>
                <div class="fsize-s light">
                    Cookies are small text files that are placed on your
                    computer by websites that you visit. They are widely used in
                    order to make websites work, or work more efficiently, as
                    well as to provide information to the owners of the site.
                    <br />
                    <br />
                    The cookies used by this website and their purpose are
                    identified and described in the table below. We will seek
                    your consent to use cookies the first time you visit this
                    website (or on subsequent occasions if the cookie that
                    records such acceptance is deleted). Denial of the cookies
                    used by this website may prevent parts of the website from
                    working properly.
                </div>
            </PageBand>

            <PageBand>
                <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                    Mainstream cookies
                </h2>
                <div class="fsize-s light">
                    <strong></strong>
                    <table class="custom-table">
                        <thead>
                            <tr>
                                <th rowspan="1" colspan="1">
                                    <strong>Cookie</strong>
                                </th>
                                <th rowspan="1" colspan="1">
                                    <strong>Name</strong>
                                </th>
                                <th rowspan="1" colspan="1">
                                    <strong>Purpose</strong>
                                </th>
                                <th rowspan="1" colspan="1">
                                    <strong>Type</strong>
                                </th>
                                <th rowspan="1" colspan="1">
                                    <strong>Expiration&nbsp;</strong>
                                </th>
                                <th rowspan="1" colspan="1">
                                    <strong>Details</strong>&nbsp;
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowspan="1" colspan="1" class="Cookie">
                                    <strong>
                                        ASP.NET session ID&nbsp;
                                        <br />
                                    </strong>
                                </td>
                                <td rowspan="1" colspan="1" class="Name">
                                    ASP.NET_SessionId
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Purpose">
                                    Each user gets assigned a 120-bit random
                                    number that is represented by a 20-character
                                    string and is used to identify the users
                                    session on the server.&nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Type">
                                    Session
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Expiration">
                                    On session end&nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Details">
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1" colspan="1" class="Cookie">
                                    <strong>
                                        Google Analytics&nbsp;
                                        <br />
                                    </strong>
                                </td>
                                <td rowspan="1" colspan="1" class="Name">
                                    _ga
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Purpose">
                                    Used to distinguish users.&nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Type">
                                    Persistent&nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Expiration">
                                    2 years&nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Details">
                                    <a
                                        href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#analyticsjs"
                                        target="_blank"
                                    >
                                        <span>Here</span>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1" colspan="1" class="Cookie">
                                    <strong>
                                        Google Analytics&nbsp;
                                        <br />
                                    </strong>
                                </td>
                                <td rowspan="1" colspan="1" class="Name">
                                    _gat
                                </td>
                                <td rowspan="1" colspan="1" class="Purpose">
                                    Used to throttle request rate.&nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Type">
                                    Persistent
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Expiration">
                                    10 minutes&nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Details">
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1" colspan="1" class="Cookie">
                                    <strong>
                                        Mainstream.com site cookie
                                        acceptance&nbsp;
                                        <br />
                                    </strong>
                                </td>
                                <td rowspan="1" colspan="1" class="Name">
                                    MainstreamCookiesAccepted
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Purpose">
                                    This cookie is used to record if a user has
                                    accepted the use of cookies on the
                                    Mainstream.com website.&nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Type">
                                    Persistent
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Expiration">
                                    1 year
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Details">
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1" colspan="1" class="Cookie">
                                    <strong>
                                        Sitecore Analytics cookie&nbsp;
                                        <br />
                                    </strong>
                                </td>
                                <td rowspan="1" colspan="1" class="Name">
                                    <span class="longString">
                                        SC_ANALYTICS_GLOBAL_COOKIE
                                    </span>
                                    &nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Purpose">
                                    To identify repeat visits from a single
                                    user, Sitecore sends a persistent session
                                    cookie to the web client.&nbsp;The name of
                                    the persistent session cookie is &nbsp;
                                    <span class="longString">
                                        SC_ANALYTICS_GLOBAL_COOKIE
                                    </span>
                                    . The persistent session&nbsp;cookie expires
                                    ten years after the last page requested from
                                    the solution by the web client.&nbsp;
                                </td>
                                <td rowspan="1" colspan="1" class="Type">
                                    Persistent
                                </td>
                                <td rowspan="1" colspan="1" class="Expiration">
                                    10 years
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Details">
                                    <a
                                        href="https://sdn.sitecore.net/upload/sitecore6/65/engagement_analytics_configuration_reference_sc65-usletter.pdf"
                                        target="_blank"
                                    >
                                        <span>Here</span>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1" colspan="1" class="Cookie">
                                    <strong>
                                        Language setting&nbsp;
                                        <br />
                                    </strong>
                                </td>
                                <td rowspan="1" colspan="1" class="Name">
                                    #Lang
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Purpose">
                                    This cookie is used to record which language
                                    the user wishes to read on
                                    Mainstream.com&nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Type">
                                    Session
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Expiration">
                                    On session end&nbsp;
                                    <br />
                                </td>
                                <td rowspan="1" colspan="1" class="Details">
                                    <br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </PageBand>

            <PageBand>
                <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                    YouTube cookies
                </h2>
                <div class="fsize-s light">
                    We embed videos from our official YouTube channel using
                    YouTube’s privacy-enhanced mode. This mode may set cookies
                    on your computer once you click on the YouTube video player,
                    but YouTube will not store personally-identifiable cookie
                    information for playbacks of embedded videos using the
                    privacy-enhanced mode. To find out more please visit
                    <a
                        href="http://www.google.com/support/youtube/bin/answer.py?hl=en-GB&answer=171780"
                        target="_blank"
                    >
                        <b>YouTube’s embedding videos information page</b>.
                    </a>
                </div>
            </PageBand>

            <PageBand>
                <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                    Google cookies
                </h2>
                <div class="fsize-s light">
                    <p>
                        These cookies are used by Google to store user
                        preferences and information when viewing pages with
                        Google maps on them. To find out more please visit the
                        <a
                            href="http://www.google.com/intl/en_ALL/help/terms_maps.html"
                            target="_blank"
                        >
                            <b>Google Maps Terms of Service</b>
                        </a>
                        .
                        <br />
                        <br />
                        Most web browsers allow some user control of most
                        cookies through the browser settings. To find out more
                        about cookies, including how to see what cookies have
                        been set and how to manage and delete them, visit{" "}
                        <a href="http://allaboutcookies.org/">
                            <b>www.allaboutcookies.org</b>
                        </a>
                        .<br />
                        <br />
                        To opt out of being tracked by Google Analytics across
                        all websites visit{" "}
                        <a
                            href="http://tools.google.com/dlpage/gaoptout"
                            target="_blank"
                        >
                            <b>http://tools.google.com/dlpage/gaoptout</b>
                        </a>
                        .<br />
                        <br />
                        You have the ability to accept or decline cookies by
                        modifying the settings in your browser. However, you may
                        not be able to use all the interactive features of our
                        site if cookies are disabled.
                        <br />
                        <br />
                        There are a number of ways to manage cookies. If you use
                        different computers in different locations you will need
                        to ensure that each browser is adjusted to suit your
                        cookie preferences.
                        <br />
                        <br />
                        You can easily delete any cookies that have been
                        installed in the cookie folder of your browser. For
                        example, if you are using Microsoft Windows Explorer 8:
                        <br />
                        <br />
                        ■ Open ‘Windows Explorer’
                        <br />
                        ■ Click on ‘Tools’ menu
                        <br />
                        ■ Click ‘Delete Browsing History’
                        <br />■ Tick ‘Cookies’ and click ‘Delete’
                    </p>
                    <p>
                        If you are not using Microsoft Windows Explorer, then
                        you should search for “cookies” in the “Help” function
                        for information on where to find your cookie folder and
                        the controls to manage them.
                        <br />
                        <br />
                        <strong>Where we store your personal data</strong>
                        <br />
                        <br />
                        All information you provide to us is stored on our
                        secure servers. Where we have given you (or where you
                        have chosen) a password which enables you to access
                        certain parts of our site, you are responsible for
                        keeping this password confidential. We ask you not to
                        share a password with anyone.
                        <br />
                        <br />
                        Unfortunately, the transmission of information via the
                        internet is not completely secure. Although we will do
                        our best to protect your personal data, we cannot
                        guarantee the security of your data transmitted to our
                        site; any transmission is at your own risk. Once we have
                        received your information, we will use strict procedures
                        and security features to try to prevent unauthorised
                        access.
                        <br />
                        <br />
                        <strong>Uses made of the information</strong>
                        <br />
                        <br />
                        We use information held about you in the following ways:
                        <br />
                        <br />
                        ■ To ensure that content from our site is presented in
                        the most effective manner for you and for your computer.
                        <br />
                        ■ To provide you with information, products or services
                        that you request from us or which we feel may interest
                        you, where you have consented to be contacted for such
                        purposes.
                        <br />
                        ■ To carry out our obligations arising from any
                        contracts entered into between you and us.
                        <br />■ To allow you to participate in interactive
                        features of our service, when you choose to do so.
                    </p>
                    <p>
                        <strong>Disclosure of your information</strong>
                        <br />
                        <br />
                        We may disclose your personal information to any member
                        of our group, which means our subsidiaries, our ultimate
                        holding company and its subsidiaries, as defined in
                        section 1159 of the UK Companies Act 2006.
                        <br />
                        <br />
                        <strong>Access to information</strong>
                        <br />
                        <br />
                        The Act gives you the right to access information held
                        about you. Your right of access can be exercised in
                        accordance with the Act. Any access request may be
                        subject to a fee of £10 to meet our costs in providing
                        you with details of the information we hold about you.
                        <br />
                        <br />
                        <strong>Changes to our privacy policy</strong>
                        <br />
                        <br />
                        Any changes we may make to our privacy policy in the
                        future will be posted on this page.
                        <br />
                        <br />
                        <strong>How to contact us</strong>
                        <br />
                        <br />
                        We respect your privacy. Any questions, comments and
                        requests regarding this privacy policy are welcomed and
                        should be addressed to info@mscsl.co.uk or by writing
                        to: KASHM Technologies LLC, 8 Copperfields,
                        Caversham, Reading, Berkshire, RG4 7PQ, United Kingdom.
                    </p>
                </div>{" "}
            </PageBand>
            <Footer />
        </div>
    );
};

export default Comp;
