import React from "react";

import { PageHead, PageBand, SocialIcon } from "msappfwk";

import "./Home.css";

import { Hero } from "../../Components/Hero/Hero.jsx";
import Offices from "../../Components/Offices/Offices.jsx";
import { Footer } from "../../Components/Footer/Footer.jsx";

/***************************************************************************************
 *
 * Home
 *
 ***************************************************************************************/

const Comp = () => {
    let keywords = "KASHM";
    keywords += ", Solution Architecture";
    keywords += ", IT services";
    keywords += ", Application Development";
    keywords += ", Agile Transformation";
    keywords += ", Testing";
    keywords += ", Outsourcing";
    keywords += ", Continuous Delivery and DevOPS";
    keywords += ", Application Management";

    return (
        <div id="home">
            <PageHead
                title="KASHM Technologies LLC"
                description="KASHM Technologies is a privately-owned IT services company, 
                with over 26 years of experience of working with some of the world?s Telecommunications, 
                Technology, and Retail companies helping them evolve and transform their businesses to meet
                the needs of today?s and tomorrow?s customers."
                keywords={keywords}
                index
            ></PageHead>

            <Hero img="/images/team1.jpeg">
                <div id="herotext">Your trusted technology advisors</div>
                <div id="socialicons">
                    <SocialIcon
                        network="linkedin"
                        url="https://linkedin.com"
                        width="40px"
                    />
                </div>
            </Hero>
            <PageBand id="band1">
                <div id="consultancy" className="infoBox">
                    <h3>KASHM</h3>
                    <h2>Consultancy</h2>
                    First class people delivering a first class service. A
                    simple philosophy that has enabled KASHM to become a
                    trusted and proven partner to world leading Media, IT and
                    Communications providers. With an outstanding talent pool,
                    highly adaptable service models and the capability to
                    rapidly mobilise, KASHM are the partner of choice for
                    your consultancy needs
                </div>
                <div id="techservices" className="infoBox">
                    <h3>KASHM</h3>
                    <h2>Technical Services</h2>
                    First class people delivering a first class service. A
                    simple philosophy that has enabled KASHM to become a
                    trusted and proven partner to world leading Media, IT and
                    Communications providers. With an outstanding talent pool,
                    highly adaptable service models and the capability to
                    rapidly mobilise, KASHM are the partner of choice for
                    your consultancy needs
                </div>
            </PageBand>
            <Offices />
            <Footer />
        </div>
    );
};

export default Comp;
