import React from "react";

import { Tabs, TabPanel, ThemeSettings } from "msappfwk";

const SettingTabs = () => {
    return (
        <Tabs initialActiveTab="theme">
            <TabPanel id="theme" label="Theme">
                <ThemeSettings />
            </TabPanel>
        </Tabs>
    );
};

export default SettingTabs;
