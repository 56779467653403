import React, { useState, useEffect } from "react";

import { Portal, ManageChoices } from "msappfwk";
import { Login, Logout, ManageUsers, ManageRoles, ManagePrivs } from "msauth";

import Home from "../Boards/Home/Home.jsx";
import Settings from "../Boards/SettingsBoard/Settings.jsx";
import PageNotFound from "./PageNotFound.jsx";

import About from "../Boards/About/About.jsx";
import Consultancy from "../Boards/Consultancy/Consultancy.jsx";
import Design from "../Boards/Design/Design.jsx";
import Contact from "../Boards/Contact/Contact.jsx";
import Privacy from "../Boards/Privacy/Privacy.jsx";
import Statements from "../Boards/Statements/Statements.jsx";

export const PortalPage = (props) => {
    const [board, setBoard] = useState(props.board || "Home");

    useEffect(() => {
        setBoard(props.board);
    }, [props.board]);

    const navBarComponent = (props) => {
        return (
            <React.Fragment>
                <div className="navPhone">
                    <a href="mailto:info@kashm.ae">info@kashm.ae</a>
                </div>
                <div className="navPhone">
                    <a href="tel:+971585692710">+971 585 692 710</a>
                </div>
                {/* <div>
                    {authContext.isLoggedIn()
                        ? authContext.getUserFullName()
                        : "Login"}
                </div> */}
            </React.Fragment>
        );
    };

    const components = {
        default: Home,
        navBarComponent: navBarComponent,

        Home: Home,
        About: About,
        Consultancy: Consultancy,
        Design: Design,

        Contact: Contact,
        Privacy: Privacy,
        Statements: Statements,

        Settings: Settings,
        Login: Login,
        Logout: Logout,
        ManageUsers: ManageUsers,
        ManageRoles: ManageRoles,
        ManagePrivs: ManagePrivs,
        ChoiceBoard: ManageChoices,
        PageNotFound: PageNotFound,
    };

    return <Portal components={components} boardName={board} noPageBand />;
};
