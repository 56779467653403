import React from "react";

import "./Offices.css";

import { Hero } from "../Hero/Hero.jsx";

/***************************************************************************************
 *
 * Offices
 *
 ***************************************************************************************/

const Comp = () => {
    return (
        <Hero img="/images/blank-world-map-transparent.png">
            <div id="herotext">Delivering solutions to keep you in front</div>
            <div id="offices">
                <h3>Offices</h3>
                <div>
                    <div className="office">Reading, UK</div>
                    <div className="office">Birmingham, UK</div>
                    <div className="office">Dubai, UAE</div>
                </div>
            </div>
        </Hero>
    );
};

export default Comp;
