import React from "react";

import { PageBand } from "msappfwk";

import "./Contact.css";

import { Hero } from "../../Components/Hero/Hero.jsx";
import Offices from "../../Components/Offices/Offices.jsx";
import { Footer } from "../../Components/Footer/Footer.jsx";

/***************************************************************************************
 *
 * Home
 *
 ***************************************************************************************/

const Comp = () => {
    return (
        <div id="home">
            <Hero img="/images/green_park.jpeg">
                <div id="herotext">Contact Us</div>
            </Hero>
            <PageBand id="band1">
            <div id="consultancy" className="infoBox">

                To contact KASHM Technologies please complete the form.
                If you would like us to call you please include your telephone
                number. We aim to respond by email within 48 hours. You can also
                find us on social networks.
                </div>
            </PageBand>
            <Footer />
        </div>
    );
};

export default Comp;
